import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

import { LoggedInProps } from '~/scenes/_app/helpers';
import { InlineHelp } from '~/ui/components/InlineHelp';
import { useSelectedAccount } from '~/ui/components/SidebarNavigation/components/useSelectedAccount';
import { A, CALLOUT } from '~/ui/components/text';

export type AccountDisabledBannerProps = {
  currentUser: LoggedInProps['currentUser'];
} & HTMLAttributes<HTMLDivElement>;

export function AccountDisabledBanner({
  className,
  currentUser,
  ...rest
}: AccountDisabledBannerProps) {
  const { selectedAccount } = useSelectedAccount(currentUser, currentUser?.accounts ?? []);

  if (!selectedAccount?.isDisabled) {
    return null;
  }

  return (
    <div
      className={mergeClasses(
        'mx-auto grid w-full max-w-screen-xl-gutters grid-cols-1 items-center px-6 pb-2 pt-8',
        className
      )}
      {...rest}>
      <InlineHelp type="warning" title="Account disabled">
        <CALLOUT>
          This account has been disabled. Please email{' '}
          <A href="mailto:support@expo.dev?subject=Account%20disabled%20question" isStyled>
            support@expo.dev
          </A>{' '}
          if you believe this was in error.
        </CALLOUT>
      </InlineHelp>
    </div>
  );
}
